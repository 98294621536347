<template>
    <div class="trash-container">
        <div class="trash-items" v-if="files.length > 0">
            <div v-for="file in files" :key="file.id">
                <FileCard :snapshot="file" :allowRestore="true" @file-deleted="handleDeletedFile"
                    style="width: 23vw;" />
            </div>
        </div>
        <h3 v-else style="text-align: center;">Nenhum item na lixeira</h3>
    </div>
</template>

<script>
import FileCard from '../../Cards/FileCard';
import firebase from "firebase";

export default {
    name: "FilesTrash",
    data() {
        return {
            files: []
        };
    },
    components: {
        FileCard,
    },
    methods: {
        async handleRestore(file) {
            console.log(file)
        },
        async handleDeletedFile() {
            console.log('deleted')
        },
        async getItems() {

            const db = firebase.firestore();
            const querySnapshot = await db.collection("assets_trash").get();

            this.files = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
        }
    },
    mounted() {
        this.getItems();
    },
}
</script>

<style scoped>
.trash-container {
    width: 60%;
    margin: auto;
}

.trash-items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}
</style>