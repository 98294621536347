var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticClass:"pa-4 grey lighten-5"},[_c('ais-instant-search',{attrs:{"name":"assets:updated_at:asc","search-client":_vm.searchClient,"index-name":"assets:updated_at:desc"}},[_c('div',{staticClass:"text-center"},[_c('ais-autocomplete',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var currentRefinement = ref.currentRefinement;
var refine = ref.refine;
return [_c('input',{staticClass:"fields",attrs:{"type":"search","placeholder":"Buscar arquivos"},domProps:{"value":currentRefinement},on:{"input":function($event){return refine($event.currentTarget.value)}}})]}}])}),_c('ais-refinement-list',{staticClass:"mb-2",attrs:{"attribute":"type","searchable":"","show-more":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
var refine = ref.refine;
var createURL = ref.createURL;
return [_c('v-layout',{staticClass:"list-wrapper mt-3"},_vm._l((items),function(item){return _c('v-flex',{key:item.value,staticClass:"list",attrs:{"xs2":""},on:{"click":function($event){$event.preventDefault();return refine(item.value)}}},[_c('a',{staticClass:"list-font",style:({ fontWeight: item.isRefined ? 'bold' : '' }),attrs:{"href":createURL(item)}},[_c('ais-highlight',{attrs:{"attribute":"item","hit":item}}),_vm._v(" ("+_vm._s(item.count.toLocaleString())+") ")],1)])}),1)]}}])}),_c('div',{staticClass:"list-wrapper mt-3"},[_c('p',{staticClass:"trash-bin",on:{"click":_vm.goToTrashBin}},[_vm._v("Lixeira")])]),_c('v-divider',{staticClass:"mt-8"}),_c('v-layout',{staticClass:"d-flex justify-center",attrs:{"wrap":""}},[_c('v-flex',{staticClass:"d-flex justify-start",attrs:{"sm6":"","md3":""}},[_c('ais-stats',{staticClass:"mt-4 normal thin",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var nbPages = ref.nbPages;
var nbHits = ref.nbHits;
return [_c('span',{staticClass:"normal gray-300"},[_c('span',{staticClass:"bold"},[_vm._v(_vm._s(_vm._f("formatNumber")(nbHits))+" ")]),_vm._v(" Resultados encontrado. ")])]}}])})],1),_c('v-flex',{attrs:{"sm8":"","md6":""}},[_c('ais-configure',{attrs:{"attributesToSnippet":['description:50'],"snippetEllipsisText":"…","hitsPerPage":20},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var searchParameters = ref.searchParameters;
var refine = ref.refine;
return _c('div',{staticClass:"d-flex justify-center"},[_c('v-card',{staticClass:"range-wrapper transparent",attrs:{"flat":""}},[_c('v-form',{staticClass:"d-flex"},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],staticClass:"mt-2 mr-1 ml-1",attrs:{"outlined":"","label":"Data inicial","dense":"","placeholder":"dd/mm/aaaa"},model:{value:(_vm.minNumber),callback:function ($$v) {_vm.minNumber=$$v},expression:"minNumber"}}),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],staticClass:"mt-2 mr-1 ml-1",attrs:{"outlined":"","dense":"","placeholder":"dd/mm/aaaa","label":"Data final","error-messages":_vm.errorMsg},model:{value:(_vm.maxNumber),callback:function ($$v) {_vm.maxNumber=$$v},expression:"maxNumber"}}),_c('v-btn',{staticClass:"mt-2 mr-1 ml-1",attrs:{"height":"40px","outlined":"","disabled":!_vm.disabledBtn &&
                      _vm.maxNumber &&
                      _vm.minNumber &&
                      _vm.maxNumber.length === 10 &&
                      _vm.minNumber.length === 10
                        ? false
                        : true},on:{"click":function () {
                        var filters = [("history " + _vm.min + " TO " + _vm.max)]
                          .filter(function (filter) { return filter; })
                          .join(' AND ');
                        refine(Object.assign({}, searchParameters,
                          {filters: filters}));
                      }}},[_vm._v(" Filtrar ")]),_c('v-btn',{staticClass:"mt-2 mr-1 ml-1",attrs:{"text":"","height":"40px"},on:{"click":function () {
                        var filters = ["size > 0"]
                          .filter(function (filter) { return filter; })
                          .join(' AND ');
                        refine(Object.assign({}, searchParameters,
                          {filters: filters}));
                        _vm.minNumber = null;
                        _vm.maxNumber = null;
                      }}},[_c('v-icon',[_vm._v("mdi-filter-off-outline")])],1)],1)],1)],1)}}])})],1),_c('v-flex',{staticClass:"d-flex justify-end",attrs:{"sm4":"","md3":""}},[_c('ais-sort-by',{staticClass:"mt-2",attrs:{"items":[
              {
                value: 'assets:updated_at:desc',
                label: 'Atualização: Decrescente',
              },
              {
                value: 'assets:updated_at:asc',
                label: 'Atualização: Crescente',
              },
              {
                value: 'assets:created_at:desc',
                label: 'Data de criação: Decrescente',
              },
              {
                value: 'assets:created_at:asc',
                label: 'Data de criação: Crescente',
              },
              {
                value: 'assets:size:asc',
                label: 'Tamanho: Crescente',
              },
              {
                value: 'assets:size:desc',
                label: 'Tamanho: Decrescente',
              } ]}})],1)],1),(!_vm.user.data.restricted)?_c('v-btn',{attrs:{"fab":"","to":"/fl/upload","color":"primary uploadBtn"}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-cloud-upload ")])],1):_vm._e()],1),_c('ais-hits',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var items = ref.items;
return [_c('v-row',{staticClass:"mt-8"},_vm._l((items),function(file,i){return _c('v-col',{key:i,attrs:{"sm":"6","md":"4","lg":"3","xl":"2"}},[_c('FileCard',{attrs:{"snapshot":file},on:{"file-deleted":_vm.handleDeletedFile}})],1)}),1)]}}])}),_c('ais-pagination',{attrs:{"total-pages":10}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }