import Vue from "vue";
import Router from "vue-router";
import ModelContainer from "./Pages/Models/ModelContainer";
import ModelList from "./Pages/Models/ModelList";
import ModelCreate from "./Pages/Models/ModelCreate";
import InProgress from "@/components/InProgress";
import FileUploadBatch from "./components/Pages/Files/FileUploadBatch";
import FileList from "./components/Pages/Files/FileList.vue";
import FileDetails from "../src/components/Pages/Files/FileDetails.vue";
import FileContainer from "./components/Pages/Files/FileContainer";
import DomainContainer from "./Pages/DomainContainer";
import AxisList from "./Pages/Axis/AxisList";
import AxisForm from "./Pages/Axis/AxisForm";
import QuizFlow from "./Pages/QuizFlow";
import QuestionForm from "./Pages/Questions/QuestionForm.vue";
import QuestionList from "./Pages/Questions/QuestionList";
import PlanetContainer from "./Pages/Digital-Activities/PlanetContainer";
import PlanetList from "./Pages/Digital-Activities/PlanetList";
import PlanetCreate from "./Pages/Digital-Activities/PlanetCreate";
import QuestionDialog from "./Pages/Digital-Activities/QuestionDialog";
import Dashboard from "./Pages/Dashboard.vue";
import SortPlanets from "./Pages/Digital-Activities/SortPlanets";
import FilesTrash from "./components/Pages/Files/FilesTrash.vue";
import store from "./store/store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: Dashboard,
      name: "dashboard",
      meta: {
        auth: true,
        name: "Dashboard",
        title: "Dashboard",
        restricted: true,
      },
    },
    {
      path: "/md",
      meta: {
        auth: true,
        title: "Modelos",
      },
      component: ModelContainer,
      children: [
        {
          path: "",
          component: ModelList,
          name: "model-list",
          meta: {
            auth: true,
            title: "Modelos",
          },
        },
        {
          path: "create",
          component: ModelCreate,
          meta: {
            auth: true,
            title: "Novo Modelo",
          },
        },
        {
          path: ":id",
          component: ModelCreate,
          props: true,
          meta: {
            auth: true,
            title: "Editar Modelo",
          },
        },
      ],
    },
    {
      path: "/fl",
      component: FileContainer,
      meta: {
        auth: true,
        title: "Arquivos",
      },
      children: [
        {
          path: "",
          component: FileList,
          meta: {
            auth: true,
            title: "Arquivos",
          },
        },
        {
          path: "details/:id",
          component: FileDetails,
          meta: {
            auth: true,
            title: "Details",
          },
        },
        {
          path: "upload",
          component: FileUploadBatch,
          meta: {
            auth: true,
            title: "Enviar Arquivos",
            restricted: true,
          },
        },
      ],
    },
    {
      path: "/vd",
      component: InProgress,
      meta: {
        auth: true,
        title: "Vídeos",
      },
    },
    {
      path: "/ma",
      component: InProgress,
      meta: {
        auth: true,
        title: "Material Adicional",
      },
    },
    {
      path: "/auth",
      name: "auth",
      component: InProgress,
      meta: {
        auth: false,
        title: "Login",
      },
    },
    {
      path: "/domain/:domain",
      meta: {
        auth: true,
        restricted: true,
      },
      props: true,
      component: DomainContainer,
      children: [
        {
          path: "",
          component: DomainContainer,
          props: true,
          meta: {
            auth: true,
          },
        },
        {
          path: "qt",
          component: QuestionList,
          name: "question-list",
          meta: {
            auth: true,
            title: "Questões",
          },
        },
        {
          path: "qt/create",
          component: QuestionForm,
          meta: {
            auth: true,
            title: "Criar Questão",
          },
        },
        {
          path: "qt/:id",
          component: QuestionForm,
          name: "question-edit",
          props: true,
          meta: {
            auth: true,
            title: "Editar Questão",
          },
        },
        {
          path: "qz",
          name: "quizflow-list",
          component: QuizFlow,
        },
        {
          path: "atividades_digitais",
          component: PlanetContainer,
          meta: {
            auth: true,
            title: "Planetas",
          },
          children: [
            {
              path: "",
              component: PlanetList,
              name: "planet-list",
              meta: {
                auth: true,
                title: "Planetas",
              },
            },
            {
              path: ":axisId/sort",
              component: SortPlanets,
              name: "da-sort",
              props: true,
              meta: {
                auth: true,
              },
            },
            {
              path: "create",
              component: PlanetCreate,
              meta: {
                auth: true,
                title: "Novo Planeta",
              },
            },
            {
              path: ":id",
              component: PlanetCreate,
              props: true,
              name: "da-planet",
              meta: {
                auth: true,
                title: "Editar Planeta",
              },
            },
            {
              path: ":planetId/qt/create",
              component: QuestionDialog,
              props: true,
              name: "da-question-create",
              meta: {
                auth: true,
                title: "Criar Questão",
              },
            },
            {
              path: ":planetId/qt/:id",
              component: QuestionDialog,
              name: "da-question-edit",
              props: true,
              meta: {
                auth: true,
                title: "Editar Questão",
              },
            },
          ],
        },
        {
          path: "ax",
          name: "axis-list",
          component: AxisList,
        },
        {
          path: "ax/create",
          component: AxisForm,
        },
        {
          path: "ax/:id",
          component: AxisForm,
          props: true,
          meta: {
            auth: true,
            title: "Editar Eixo",
          },
        },
      ],
      scrollBehavior() {
        return { x: 0, y: 0 };
      },
    },
    {
      path: "/trash",
      component: FilesTrash,
      meta: {
        auth: true,
        title: "Lixeira",
      },
    }
  ],
});

router.beforeEach((to, _, next) => {
  if (to.matched.some((r) => r.meta?.restricted)) {
    if (store.getters.user.data?.restricted) {
      next("/fl");
    }
  }
  next();
});

export default router;
